/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { MutableRefObject } from "react";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Observable, Subject, Subscription } from "rxjs";

export { Observable, Subject, Subscription };

export type ResultOf<T> = T extends Observable<infer A> ? A : never;

export function useSources<T>(
  initialSources: {
    [Key in keyof T]: Subject<T[Key]>;
  },
) {
  const [sources] = useState(() => initialSources);

  return sources;
}

export interface Register {
  make: (source: Subject<any>) => Observable<any>;
  complete: ((result: any) => void) | null;
}

export type Registrations<Keys extends string> = { [Key in Keys]: Register };

export function useRegister<Keys extends string>(
  subscriptions: Registrations<Keys>,
) {
  return useRef<Registrations<Keys>>(subscriptions);
}

export function useEpics<Keys extends string>(
  sources: { [Key in Keys]: Subject<any> },
  subscriptions: MutableRefObject<Registrations<Keys>>,
) {
  const onComplete = useCallback((key: Keys, result: any) => {
    const { complete } = subscriptions.current[key as Keys];

    if (!complete) {
      throw new Error(`missing complete for ${key}`);
    }

    return complete(result);
  }, []);

  useLayoutEffect(() => {
    const current = Object.entries<Register>(subscriptions.current).map(
      ([key, { make }]) =>
        make(sources[key as Keys]).subscribe((result) =>
          onComplete(key as Keys, result),
        ),
    );

    return () => current.forEach((curr) => curr.unsubscribe());
  }, []);
}
