import * as Sentry from "@sentry/react";
import React from "react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  DOPPLER_CONFIG,
  DOPPLER_ENVIRONMENT,
  RELEASE,
  SENTRY_DSN,
} from "../conf";
import type { AppError } from "./errors";

Sentry.init({
  enabled: typeof SENTRY_DSN === "string",
  environment: DOPPLER_CONFIG,
  release: RELEASE,
  dsn: SENTRY_DSN,
  tracePropagationTargets:
    DOPPLER_ENVIRONMENT === "dev"
      ? ["localhost", /^https:\/\/.*\.endear\.ngrok\.io/]
      : [
          /^https:\/\/(app-embedded|web-embedded|app-embedded.stg|web-embedded.stg|app-embedded-stg|web-embedded-stg)\.endearhq\.com/,
        ],
  tracesSampleRate: 0.25,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export { Sentry };

export const reportBoundaryError = (error: unknown, errorInfo: unknown) => {
  Sentry.withScope((scope) => {
    if (typeof errorInfo === "object" && errorInfo !== null) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key as keyof typeof errorInfo]);
      });
    }

    Sentry.captureException(error);
  });
};

export class ErrorReporter extends EventTarget {}

export const errorReporter = new ErrorReporter();

export function reportError(error: AppError) {
  errorReporter.dispatchEvent(
    new CustomEvent("appError", { detail: { error } }),
  );
}
