import type { Observable } from "rxjs";
import { empty, throwError, timer } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { redirectToLogin } from "../conf";
import type { ExtendableError } from "../services/errors";
import { AuthenticationError, CodeError, ErrorCode } from "../services/errors";

const ERROR_CODES_TO_RETRY = [ErrorCode.UnexpectedStatus, ErrorCode.Offline];

export function retryOnServiceUnavailable(
  options: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
  } = {},
) {
  const { maxRetryAttempts = 3, scalingDuration = 1000 } = options;

  return (attempts: Observable<ExtendableError>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          (error instanceof CodeError &&
            !ERROR_CODES_TO_RETRY.includes(error.code))
        ) {
          return throwError(error);
        }
        const retryAfter = retryAttempt * scalingDuration;

        if (error instanceof AuthenticationError) {
          redirectToLogin();
          return empty();
        }

        console.debug(
          `Unexpected Error: Attempt ${retryAttempt}: retrying in ${retryAfter}ms`,
        );
        // retry after 1s, 2s, etc...
        return timer(retryAfter);
      }),
    );
  };
}
